/**
 * Descriptive Text for `One-on-One` types
 */
const descriptions = {
  "Personal/Professional Development": `
  <p><em>Personal/Professional Development</em> one on ones are designed to give Yoga Teachers and Yoga Therapists the opportunity to have a productive professional relationship with an established peer in the field. You will receive guidance on direction and advice with regards to launching a successful career in the health and wellness industry. This offering is designed for yoga teachers that are both new or experienced in the industry and would like to continue their journey and bring new techniques and experiences to the mat. You will learn how to:</p>
    <ul>
        <li>Identify values, establish boundaries and set intentions </li>
        <li>Integrate yoga into your personal and professional life </li>
        <li>Create a ritual around practice and use your practice as inspiration </li>
        <li>Speaking your truth and identifying your heartfelt desires </li>
        <li>Identify the limiting beliefs that are holding you back </li>
        <li>Exalt your intentions and manifest your heartfelt desires</li>
    </ul>
  `,

  "Private Yoga Session": `
  <p><em>Private yoga classes</em> can help you establish a personal practice or fine-tune your alignment. <b>My Vinyasa Practice</b> has network of dedicated teachers who love working with clients one on one to help them develop a personal practice and see lasting results. Join us in-person at our Austin, TX studio!</p>
  `,

  "Zoom Private Yoga Session": `
  <p><em>Private yoga classes</em> can help you establish a personal practice or fine-tune your alignment. <b>My Vinyasa Practice</b> has created a network of teachers located all over the world who love working with clients one on one to help them develop a personal practice and see lasting results. Connect with a teacher via Zoom from anywhere around the world.</p>
  `,

  "Yoga Therapy Clinic": `
  <p>Our <em>Yoga Therapy Clinic</em> is a free service where students can see one of our Yoga Therapists in training. All Yoga Therapist in training are supervised by our IAYT Certified Yoga Therapist. Gain insight and guidance into how yoga, meditation, and mindfulness practices can help you live the life you are meant to live with the help of a Yoga Therapist in training. Yoga Therapy is a combination of yoga, mindfulness and meditation that is designed to help clients integrate on a truly holistic level and work towards a more balanced experience in all four energetic bodies. Clients are coached through various therapeutic techniques including Dialectical Behavioral Therapy, meditation, journaling, and the physical practice of yoga.</p>
  <p><b>My Vinyasa Practice</b> is dedicated to using these tools to create a comprehensive plan that will help you to find balance and peace in your daily life. Our approach to Yoga Therapy meets you where you are at and helps you to achieve a sustainable lifestyle that fosters peace and balance in all four energetic bodies.</p>
  `,

  "Yoga Therapy": `
  <p>Gain insight and guidance into how yoga, meditation, and mindfulness practices can help you live the life you are meant to live with the help of a IAYT Certified Yoga Therapist. Yoga Therapy is a combination of yoga, mindfulness and meditation that is designed to help clients integrate on a truly holistic level and work towards a more balanced experience in all four energetic bodies. Clients are coached through various therapeutic techniques including Dialectical Behavioral Therapy, meditation, journaling, and the physical practice of yoga.</p>
  <p><b>My Vinyasa Practice</b> is dedicated to using these tools to create a comprehensive plan that will help you to find balance and peace in your daily life. Our approach to Yoga Therapy meets you where you are at and helps you to achieve a sustainable lifestyle that fosters peace and balance in all four energetic bodies.</p>
  `,

  "Mindfulness Coaching": `
  <p>Overcome limiting beliefs and realize your true potential with the help of a Mindfulness Coach. Our coaches will teach you how to leverage mindfulness tools to manage thoughts and feelings, identify judgements, clear energetic blockages and find sustainable balance. Book your appointment today to start leveling up your life.</p>
  `
};

export default function getOneOnOneDescription(title) {
  return descriptions[title] || null;
}
