<template>
  <div id="one-on-one-form" class="slide-up-fade-in" v-if="showConfirmation">
    <hr class="divider divider--tiny grey-white" />
    <h3 class="h5">Request received!</h3>

    <branded-divider />

    <p>
      Thank you for booking a <b>{{ form.name }}</b> session with
      <b>{{ appOrgName }}</b
      >. We are so thankful to work with you! Your request has been received:
      please allow up to <b>48 hours</b> for a response.
    </p>

    <router-link
      class="button flex--centered outline list-item wide"
      :to="listRoute"
      title="Return to My One-on-One requests"
    >
      <span class="success--text">
        <i class="fas fa-arrow-left"></i>
        My requests
      </span>
    </router-link>
  </div>

  <form
    v-else
    id="one-on-one-form"
    class="form-component"
    @submit.prevent="onSubmit"
  >
    <loader v-if="loading" :loading-message="loadingMessage" />

    <!-- User Info -->
    <one-on-one-form-user :form-data="formData" @change="appendFormData" />

    <!-- Date/Time zone -->
    <one-on-one-form-schedule :form-data="formData" @change="appendFormData" />

    <!-- Type/Practitioner -->
    <one-on-one-form-details :form-data="formData" @change="appendFormData" />

    <!-- Donation notice -->
    <one-on-one-form-notice :form-data="formData" @change="appendFormData" />

    <p class="list-item list-item--label error--text" v-if="error">
      <span><b>Could not complete request:</b> {{ error }}</span>
    </p>

    <button type="submit" :disabled="!formIsValid" class="success">
      Schedule a Session
    </button>
  </form>
</template>

<script>
import { createOrUpdateOneOnOne } from "../models/oneOnOne";
import BrandedDivider from "./BrandedDivider.vue";
import Loader from "./Loader.vue";
import DateTimeMixin from "./mixins/date-time.mixin";
import FormsMixin from "./mixins/forms.mixin";
import PermissionsMixin from "./mixins/permissions.mixin";
import OneOnOneFormDetails from "./OneOnOneForm.Details.vue";
import OneOnOneFormNotice from "./OneOnOneForm.Notice.vue";
import OneOnOneFormSchedule from "./OneOnOneForm.Schedule.vue";
import OneOnOneFormUser from "./OneOnOneForm.User.vue";

export default {
  name: "OneOnOneForm",

  components: {
    Loader,
    BrandedDivider,
    OneOnOneFormUser,
    OneOnOneFormSchedule,
    OneOnOneFormDetails,
    OneOnOneFormNotice
  },

  mixins: [DateTimeMixin, FormsMixin, PermissionsMixin],

  data: () => ({
    form: { name: null, donationConsent: true, assignedTeacher: null },
    showConfirmation: false
  }),

  computed: {
    listRoute() {
      return { name: "ListOneOnOne" };
    },

    requiredFields() {
      return [
        "assignedTeacher",
        "name",
        "requestedBy",
        "scheduledDate",
        "scheduledTimeZone",
        "userEmail",
        "userName"
      ];
    }
  },

  methods: {
    async onSubmit() {
      this.startLoading("Placing request ...");

      try {
        await createOrUpdateOneOnOne(this.form);
        const $elem = document.querySelector("#app");
        if ($elem) $elem.scrollTo({ top: 0 });
        this.showConfirmation = true;
        this.stopLoading();
      } catch (error) {
        this.onViewError(error.message || error);
      }
    }
  }
};
</script>

<style lang="scss">
#one-on-one-form {
  max-width: $form-width;

  fieldset {
    margin-bottom: $xlg;
  }
}

@media screen and (max-width: 600px) {
  #one-on-one-form {
    .form--row {
      flex-direction: column;

      > * {
        width: $parent;
      }
    }
  }
}
</style>
