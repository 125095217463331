<template>
  <fieldset class="one-on-one-form--user form--row">
    <legend>
      <i class="fas fa-user-circle accent--text" />
      Your Details
    </legend>

    <!-- Name -->
    <div class="form--row grow">
      <span :class="`${requiredField('userName')} no-shrink`">Name: </span>
      <span class="list-item list-item--label grey--text">
        {{ form.userName }}
      </span>
    </div>

    <hr class="divider divider--vertical" />

    <!-- Email -->
    <div class="form--row col-50">
      <span :class="`${requiredField('userEmail')} no-shrink`">Email: </span>
      <span class="list-item list-item--label grey--text">{{
        form.userEmail
      }}</span>
    </div>
  </fieldset>
</template>

<script>
import FormsMixin from "./mixins/forms.mixin";
import PermissionsMixin from "./mixins/permissions.mixin";

export default {
  name: "OneOnOneFormUser",

  mixins: [FormsMixin, PermissionsMixin],

  computed: {
    requiredFields() {
      return ["requestedBy", "userEmail", "userName"];
    }
  },

  mounted() {
    if (this.activeUser) {
      this.appendFormData({
        requestedBy: this.activeUser.id,
        userName: this.activeUser.fullName,
        userEmail: this.activeUser.email
      });

      this.emitFormData();
    }
  }
};
</script>

<style lang="scss">
.one-on-one-form--user {
  padding-bottom: $xxs;

  .list-item--label {
    @include rounded;
    background-color: $grey-white;
    flex-grow: 1;
    padding-left: $xxs;
    width: auto;
  }
}
</style>
