<template>
  <label for="classCap" class="autocomplete col-33">
    <span class="h6" :class="{ 'error--text': invalid, required }">
      <i v-if="glyph" :class="`accent--text fas fa-${glyph}`"></i>
      <span>&nbsp;{{ label }}</span>
    </span>

    <button
      class="button--toggle outline"
      @click.stop="showForm"
      @focus.stop="showForm"
      v-if="disabled"
    >
      <span class="ellipsis">{{ selected || placeholder }}</span>
    </button>

    <input
      v-else
      autofocus
      :class="searchInputId"
      :name="searchInputId"
      :placeholder="placeholder"
      :readonly="disabled"
      @blur="resetOrSubmit"
      @input="showMatches(($event || { value: null }).target)"
      type="text"
      v-model="searchInput"
    />

    <div class="results list" v-if="noResults">
      <em class="list-item">No results found</em>
    </div>

    <ul v-else class="results list">
      <li
        class="list-item"
        v-for="(result, i) in searchResults"
        :key="i"
        @click="onResultSelect(result)"
      >
        <span class="grow">{{ result[displayKey] }}</span>
        <em v-if="abbrKey" class="grey--text no-shrink">
          {{ result[abbrKey] }}
        </em>
      </li>
    </ul>
  </label>
</template>

<script>
import KeyboardEventsMixin from "./mixins/keyboard-events.mixin";

export default {
  name: "AutoComplete",

  mixins: [KeyboardEventsMixin],

  props: {
    abbrKey: { type: String, default: "abbr" },
    displayKey: { type: String, required: true },
    glyph: String,
    invalid: Boolean,
    label: { type: String, required: true },
    options: { type: Array, required: true, validate: o => o.name },
    placeholder: { type: String, default: "Search for a value" },
    required: Boolean,
    value: { type: Object }
  },

  data: () => ({
    disabled: true,
    searchInput: null,
    searchInputId: "search-input",
    searchResults: []
  }),

  computed: {
    noResults() {
      const hasSearch = this.searchInput && this.searchInput.length > 2;
      return hasSearch && this.searchResults.length === 0;
    },

    searchKeys() {
      return Object.keys(this.options[0]);
    },

    selected() {
      if (this.value) {
        return this.value.name || this.value.text || this.value;
      } else return null;
    }
  },

  methods: {
    clearSearch() {
      this.searchInput = null;
      this.searchResults = [];
    },

    onResultSelect(result) {
      this.clearSearch();
      return this.$emit("input", result);
    },

    resetOrSubmit() {
      this.disabled = true;
      if (this.searchInput) this.searchInput = null;
    },

    showMatches({ value }) {
      if (!value || value.length < 2) return (this.searchResults = []);
      // Search and return top 5 matches
      const keys = this.searchKeys;
      const matchesValue = opt => keys.some(k => match(value, opt[k]));
      this.searchResults = this.options.filter(matchesValue).slice(0, 5);
      // Fuzzy search if no "start-of-string" search results
      if (value && this.searchResults.length === 0) {
        const fuzzy = opt => keys.some(k => match(value, opt[k], true));
        this.searchResults = this.options.filter(fuzzy).slice(0, 5);
      }
    },

    showForm(/* e */) {
      this.disabled = false;
      this.attachListeners();
    },

    cancelActiveState() {
      this.disabled = true;
      this.clearSearch();
      this.detachListeners();
    },

    exitActiveState(e) {
      const $target = (e || {}).target;
      // Deactivate Search input and clear window listeners
      if (!e || $target.getAttribute("name") !== this.searchInputId) {
        this.cancelActiveState();
      }
    }
  }
};

function match(searchString, target, fuzzyMatch = false) {
  const searchVal = fuzzyMatch ? searchString : `^${searchString}`;
  const match = new RegExp(searchVal, "gmi");
  return match.test(target);
}
</script>

<style lang="scss" src="./AutoComplete.scss"></style>
