<template>
  <auto-complete
    label="Time zone"
    :glyph="labelIcon"
    :invalid="invalid"
    :options="timezones"
    :required="required"
    :value="timezone"
    @input="onTimezoneSelect"
    display-key="text"
  />
</template>

<script>
/* eslint-disable no-undef */
import { TIMEZONES, ZOOM_TIMEZONES } from "@/utilities";
import AutoComplete from "./AutoComplete";

export default {
  components: { AutoComplete },
  name: "TimeZonePicker",
  props: {
    disabled: Boolean,
    invalid: Boolean,
    required: Boolean,
    showLabelIcon: { type: Boolean, default: true },
    value: String,
    zoomTimeZones: { type: Boolean, default: true }
  },

  data: () => ({ Timezones: TIMEZONES }),

  computed: {
    labelIcon() {
      return this.showLabelIcon ? "globe" : null;
    },
    timezones() {
      return [...(this.zoomTimeZones ? ZOOM_TIMEZONES : TIMEZONES)];
    },
    timezone() {
      if (this.value) {
        return this.timezones.find(z => {
          const matchValue = k => z[k] === this.value;
          return Object.keys(z).some(matchValue);
        });
      } else return null;
    }
  },

  methods: {
    onTimezoneSelect(timezone) {
      return this.$emit("input", timezone);
    }
  }
};
</script>
