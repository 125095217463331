<template>
  <fieldset class="one-on-one-form--schedule form--row flex--wrap">
    <legend>
      <i class="fas fa-clock accent--text" />
      Date &amp; Time
    </legend>

    <div class="form--row">
      <!-- Date -->
      <label class="column grow">
        <span :class="requiredField('scheduledDate')">Request Date</span>
        <datepicker
          :format="dateTimeShortFormat"
          :hour-time="12"
          :min-date="minDate"
          :minute-step="15"
          :value-format="dateInputFormat"
          :value="form.scheduledDate"
          no-toggle-time-picker
          start-from-sunday
          @input="appendAndEmit({ scheduledDate: $event })"
        />
      </label>

      <hr class="divider divider--vertical" />

      <!-- Time zone -->
      <time-zone-picker
        zoom-time-zones
        class="column col-50"
        required
        :invalid="invalidFields.includes('scheduledTimeZone')"
        :show-label-icon="false"
        :value="form.scheduledTimeZone"
        @input="appendAndEmit({ scheduledTimeZone: $event.text })"
      />
    </div>

    <div class="form--row" :class="{ wrap: dateOptions > 1 }">
      <template v-if="dateOptions > 1">
        <!-- Date -->
        <label class="column grow">
          <span :class="requiredField('backupDate1')">Alternate Date #1</span>
          <datepicker
            :format="dateTimeShortFormat"
            :hour-time="12"
            :min-date="minDate"
            :minute-step="15"
            :value-format="dateInputFormat"
            :value="form.backupDate1"
            no-toggle-time-picker
            start-from-sunday
            @input="appendAndEmit({ backupDate1: $event })"
          />
        </label>

        <hr class="divider divider--vertical" />

        <label class="column grow" v-if="dateOptions > 2">
          <span :class="requiredField('backupDate2')">Alternate Date #2</span>
          <datepicker
            :format="dateTimeShortFormat"
            :hour-time="12"
            :min-date="minDate"
            :minute-step="15"
            :value-format="dateInputFormat"
            :value="form.backupDate2"
            no-toggle-time-picker
            start-from-sunday
            @input="appendAndEmit({ backupDate2: $event })"
          />
        </label>
      </template>

      <!-- Add/Remove backup dates -->
      <label
        v-for="(option, i) in dateOptionControls"
        :class="option.class"
        :key="i"
      >
        <span class="h6 grey--text" v-if="option.label">
          {{ option.label }}
        </span>

        <button class="outline add-backup-date" @click.prevent="option.click">
          <span :class="option.textClass">
            <i :class="option.textIcon"></i>
            {{ option.text }}
          </span>
        </button>
      </label>
    </div>
  </fieldset>
</template>

<script>
import { utcFromISO } from "../helpers/date-time.helpers";
import DateTimeMixin from "./mixins/date-time.mixin";
import FormsMixin from "./mixins/forms.mixin";
import PermissionsMixin from "./mixins/permissions.mixin";
import TimeZonePicker from "./TimeZonePicker.vue";

export default {
  name: "OneOnOneFormSchedule",

  components: { TimeZonePicker },

  mixins: [DateTimeMixin, FormsMixin, PermissionsMixin],

  data: () => ({ dateOptions: 1 }),

  computed: {
    dateOptionControls() {
      const { dateOptions } = this;

      return [
        {
          class: {
            column: true,
            wide: [1, 3].includes(dateOptions),
            grow: dateOptions === 2
          },
          click: () => this.setDateOptions(dateOptions + 1),
          condition: dateOptions < 3,
          label: "(Optional)",
          text: "Add Backup Date",
          textClass: "link",
          textIcon: "fas fa-plus-circle"
        },
        {
          class: "column wide",
          click: this.removeBackupDate,
          condition: dateOptions > 1,
          text: "Remove Backup Date",
          textClass: "error-light--text",
          textIcon: "fas fa-minus-circle"
        }
      ].filter(({ condition }) => condition);
    },

    minDate() {
      const nowISO = new Date().toISOString();
      const tz = this.activeUser ? this.activeUser.timeZone : this.localTZ;
      const nowUTC = utcFromISO(nowISO, tz);
      const twoDays = nowUTC.plus({ days: 2 });
      return twoDays.set({ hour: 9, minute: 0 });
    },

    requiredFields() {
      return [
        "scheduledDate",
        "backupDate1",
        "backupDate2",
        "scheduledTimeZone"
      ];
    }
  },

  mounted() {
    const formData = {
      donationConsent: true,
      scheduledDate: this.minDate
    };

    if (this.activeUser) {
      formData.scheduledTimeZone = this.activeUser.timeZone;
    }

    this.appendAndEmit(formData);
  },

  methods: {
    addBackupDate() {
      if (this.dateOptions === 1) return;
      const { scheduledDate, backupDate1 } = this.form;

      switch (true) {
        case this.dateOptions === 3:
          return this.appendAndEmit({
            backupDate2: backupDate1.plus({ hours: 2 })
          });

        default:
          return this.appendAndEmit({
            backupDate1: scheduledDate.plus({ hours: 2 })
          });
      }
    },

    removeBackupDate() {
      if (this.dateOptions === 1) return;

      switch (true) {
        case this.dateOptions === 3:
          this.setDateOptions(2);
          return this.appendAndEmit({ backupDate2: null });

        default:
          this.setDateOptions(1);
          return this.appendAndEmit({ backupDate1: null });
      }
    },

    setDateOptions(dateOptions) {
      const setNewDate = dateOptions > this.dateOptions;
      this.dateOptions = dateOptions;

      if (setNewDate) return this.addBackupDate();
    }
  }
};
</script>

<style lang="scss">
.one-on-one-form--schedule {
  .add-backup-date {
    margin-top: 0;
    width: $parent;
  }
}
</style>
