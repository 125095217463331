<template>
  <fieldset class="slide-down-fade-in">
    <legend>
      <i class="fas fa-hand-holding-heart error--text"></i>
      Donation
    </legend>

    <p>
      Appointments operate on a donation basis with a minimum donation of
      <b>$50 per 45 minute appointment</b>. Typically, any additional
      information and frequency of future visits will be decided during your
      first appointment.
      <em class="primary-dark--text">
        All requests must be made at least <b>48 hours</b> ahead of time.
      </em>
    </p>

    <div class="slide-down-fade-in" v-if="!form.donationConsent">
      <hr class="divider divider--tiny" />
      <p>
        <b>Can't Donate?</b> That's okay! We also have free appointments
        available with {{ appOrgName }}'s Yoga Therapists-in-training.
      </p>
    </div>

    <label for="donationConsent" class="flex slide-down-fade-in">
      <input
        @change="emitFormData"
        type="checkbox"
        name="donationConsent"
        v-model="form.donationConsent"
        class="flex form--row"
        @click.stop="appendFormData({ donationConsent: !form.donationConsent })"
      />

      <span class="checkbox-label grey--text">
        I would like to support
        <strong>My Vinyasa Practice</strong> with a donation
      </span>
    </label>
  </fieldset>
</template>

<script>
import FormsMixin from "./mixins/forms.mixin";
import PermissionsMixin from "./mixins/permissions.mixin";

export default {
  name: "OneOnOneFormNotice",

  mixins: [FormsMixin, PermissionsMixin],

  computed: {
    requiredFields() {
      return ["donationConsent"];
    }
  },

  mounted() {
    this.appendFormData({ donationConsent: true });
  }
};
</script>
