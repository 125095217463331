<template>
  <fieldset class="one-on-one--details">
    <legend>
      <i class="fas fa-people-arrows accent--text"></i>
      Details
    </legend>

    <div class="form--row">
      <!-- Appointment Type -->
      <label for="name" class="column grow">
        <span :class="requiredField('name')">Scheduling Type: </span>
        <select name="name" @change="emitFormData" v-model="form.name">
          <option :value="null">Select Scheduling type:</option>
          <optgroup label="Scheduling Types">
            <option
              v-for="(type, i) in oneOnOneTypes"
              :key="i"
              :value="type.title"
            >
              <span class="justify wide">
                <span>{{ `${type.title} (${type.length} mins)` }}</span>
              </span>
            </option>
          </optgroup>
        </select>
      </label>

      <hr class="divider divider--vertical" />

      <!-- Practitioner -->
      <mvp-instructor-select
        :show-icon="false"
        :value="form.assignedTeacher"
        @change="assignTeacher"
        class="column col-50"
        instructor-type="Practitioners"
        label-text="Requested Practitioner"
        required
      />
    </div>

    <template v-if="form.name">
      <h5 class="category-description__title">{{ form.name }}</h5>
      <div class="category-description" v-html="selectedDescription" />
    </template>
  </fieldset>
</template>

<script>
import FormsMixin from "./mixins/forms.mixin";
import PermissionsMixin from "./mixins/permissions.mixin";
import { categoryFor, oneOnOneTypes } from "../helpers/one-on-one.helpers";
import MvpInstructorSelect from "./MvpInstructorSelect.vue";
import getOneOnOneDescription from "../helpers/one-on-one.descriptions";

export default {
  name: "OneOnOneFormDetails",

  mixins: [FormsMixin, PermissionsMixin],

  components: { MvpInstructorSelect },

  data: () => ({ oneOnOneTypes }),

  computed: {
    requiredFields() {
      return ["assignedTeacher", "name"];
    },

    selectedCategory() {
      return this.form.name ? categoryFor(this.form) : null;
    },

    selectedDescription() {
      return this.form.name ? getOneOnOneDescription(this.form.name) : null;
    }
  },

  methods: {
    assignTeacher({ teacher: assignedTeacher }) {
      this.appendFormData({ assignedTeacher });
      this.emitFormData();
    }
  }
};
</script>
